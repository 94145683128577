



















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { getCodeList, submitFreetrial, sendCode } from '@/api/home';
import utils from '@/utils/index';

@Component({
    name: 'SimpleForm'
})

export default class SimpleForm extends Vue {
    $t: any

    @Prop({
        default: false
    })
    isRow!:boolean;

    @Prop({
        type: Object,
        default: () => {
            return {};
        }
    })
    storeData!:Record<string, unknown>

    @Prop({
        default: 1
    }) systemType!: number;

    @Prop({
        default: 1
    }) submitSource!: number;

    private simpleForm:any = {
        prex: '+66',
        mobile: '',
        name: '',
        verifyCode: ''
    };

    private flag = '';

    private isDisabled = false;

    private countryList = [];

    private sendBtnActive = false

    private countDown = 60

    private verify = true

    created():void {
        getCodeList('erp').then((res:any) => {
            this.countryList = (res.data || []).map((item: any) => {
                item.countryFlag = item.countryFlag.replace('https://image-cdn.jimuitech.com', 'https://images.whats-egg.com');
                item.countryFlag = item.countryFlag.replace('https://image-cdn.eggmalltech.com', 'https://images.whats-egg.com');
                return item;
            });
            this.flag = (this.countryList[0] as any).countryFlag;
        });
    }

    mounted():void {
        Object.assign(this.simpleForm, this.storeData.simpleForm);
        this.isDisabled = this.storeData.isDisabled as boolean;
    }

    private changeAreaCode() {
        const flag = this.countryList.filter((item:any) => item.areaCode === this.simpleForm.prex.slice(1)) as any;
        this.flag = flag[0].countryFlag;
        console.log('this.', this.flag);

    }

    get simpleRules():any {
        const mobileValidator = (rule:any, value:string, callback:any) => {
            const country = this.countryList.filter((item:any) => item.areaCode === this.simpleForm.prex.slice(1));
            const mobileRule = new RegExp((country[0] as any).mobileRule);
            if (!mobileRule.test(value)) {
                callback(this.$i18n.t('landingWeb.checkNumberFormat'));
                this.sendBtnActive = false;
            } else {
                callback();
                this.sendBtnActive = true;
            }
        };

        return {
            mobile: [
                { required: true, message: this.$i18n.t('landingWeb.fieldRequired'), trigger: 'blur' },
                { validator: mobileValidator, trigger: 'blur' }
            ],
            name: [
                { required: true, message: this.$i18n.t('landingWeb.fieldRequired'), trigger: 'blur' }
            ],
            verifyCode: [
                { required: true, message: this.$i18n.t('landingWeb.fieldRequired'), trigger: 'blur' }
            ]
        };
    }


    private handleSubmit() {
        (this.$refs.simpleForm as ElForm).validate((valid) => {
            if (valid) {
                submitFreetrial({
                    ...this.simpleForm,
                    prex: this.simpleForm.prex.slice(1),
                    submitSource: this.submitSource,
                    applySystemType: this.systemType
                }).then((res:any) => {
                    if (res.code === '200') {
                        this.$message.success(this.$t('home.submitSuccess'));
                        this.isDisabled = true;
                        window.gtag('event', 'free_trial_submit');
                        utils.fbq('trackCustom', 'free_trial_submit');
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        });
    }

    private handleSend() {
        if (!this.simpleForm.mobile) {
            return;
        }
        const timer = setInterval(() => {
            this.countDown--;
            this.verify = false;
            if (this.countDown === 0) {
                clearInterval(timer);
                this.verify = true;
                this.countDown = 60;
            }
        }, 1000);
        sendCode({
            prefix: this.simpleForm.prex.slice(1),
            cellphone: this.simpleForm.mobile
        }, 'erp').then((res:any) => {
            if (res.code === '20049024') {
                this.$message.success(`${this.$i18n.t('landingWeb.verifySuccess')}`);
            } else {
                clearInterval(timer);
                this.verify = true;
                this.$message.error(res.message);
            }
        }).catch(() => {
            clearInterval(timer);
            this.verify = true;
        });
    }
}

import { VuexModule, Mutation, Action, getModule, Module } from 'vuex-module-decorators';
import store from '..';
import { getLocal } from '@/lang';

export interface IEGGAlaiState {
    topForm:Record<string, unknown>,
    bottomForm:Record<string, unknown>
}

@Module({ dynamic: true, store, name: 'EGGAlai' })
class EGGAlai extends VuexModule implements IEGGAlaiState {

  public topForm = {}
  public bottomForm = {}

  public countryList = []

  @Mutation
  private SET_TOP_FORM(topForm: Record<string, unknown>) {
      this.topForm = topForm;
  }

  @Action
  public SetTopForm(topForm: Record<string, unknown>) {
      this.SET_TOP_FORM(topForm);
  }

  @Mutation
  private SET_BOTTOM_FORM(bottom: Record<string, unknown>) {
      this.bottomForm = bottom;
  }

  @Action
  public SetBottomForm(bottom: Record<string, unknown>) {
      this.SET_BOTTOM_FORM(bottom);
  }
}

export const EGGAlaiModule = getModule(EGGAlai);
